@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentContainer {
    width: 66vw;
    padding-top: 40px;
    padding-bottom: 40px;
}

.textLine {
    font-size: 15px;
    color: $textGrey;
    white-space: pre-wrap;
    text-align: justify;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .container {
        width: 100vw;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contentContainer {
        width: 90vw;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .textLine {
        font-size: 15px;
        color: $textGrey;
        white-space: pre-wrap;
        text-align: justify;
        margin-bottom: 20px;
    }
}