@import "./../consts/LAYOUT.scss";

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 1000;
}

.image {
    aspect-ratio: 1.770;
    max-width: 120px; // Default max width

    /* Responsive Scaling */
    @media (max-width: 1024px) {
        max-width: 100px;
    }

    @media (max-width: 768px) {
        max-width: 80px;
    }

    @media (max-width: 480px) {
        max-width: 60px;
    }
}