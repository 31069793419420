@import './../../consts/LAYOUT.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1;
    width: 100vw;
    min-height: 40vh;
    padding: 40px; // Added padding for better spacing

    @media (max-width: 768px) {
        padding: 30px;
        min-height: 50vh;
    }

    @media (max-width: 480px) {
        padding: 20px;
        min-height: 60vh;
    }
}

.contentContainer {
    // position: absolute;
    z-index: 2;
    width: 80%;
    max-width: 600px;
    text-align: center;
    padding: 20px; // Added padding to prevent text from touching edges

    @media (max-width: 768px) {
        width: 90%;
        padding: 15px;
    }

    @media (max-width: 480px) {
        width: 95%;
        padding: 10px;
    }
}

.title {
    color: $white;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }
}

.subtitle {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: left;
    padding: 0 20px; // Added side padding to prevent text from touching edges

    @media (max-width: 768px) {
        font-size: 15px;
        padding: 0 15px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        padding: 0 10px;
    }
}

.subtitleCta {
    color: $black;
    font-size: 16px;
    text-decoration: underline;
    margin-top: 10px;

    @media (max-width: 768px) {
        font-size: 15px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }
}

.backgroundImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover; // Ensures it stretches to fill the container
}