@import "./../../consts/LAYOUT.scss";

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $brandYellow;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 800px;
    height: 60vh;
    transition: width 0.5s;

    @media screen and (max-width: 1400px) {
        width: 700px;
    }

    @media screen and (max-width: 1024px) {
        width: 500px;
        // width: 90%;
        height: auto;
    }

    @media screen and (max-width: 600px) {
        width: 90%;
    }
}

.closeIconContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    cursor: pointer;
}

.closeIcon {
    color: $black;
    font-size: 34px;
}

.title {
    color: $black;
    font-size: 50px;
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 1400px) {
        font-size: 40px;
    }

    @media screen and (max-width: 1024px) {
        font-size: 35px;
    }

    @media screen and (max-width: 768px) {
        font-size: 25px;
    }

    @media screen and (max-width: 480px) {
        font-size: 20px;
        margin-left: 10%;
        margin-right: 10%;
    }
}

.subtitle {
    color: $black;
    font-size: 16px;
    overflow-y: scroll;
    max-height: 500px;
    padding-right: 20px;

    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }

    @media screen and (max-width: 768px) {
        font-size: 13px;
    }

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
}

.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.imagesContainer {
    position: relative;
    margin: 30px 70px;

    @media screen and (max-width: 1400px) {
        margin: 20px 50px;
    }

    @media screen and (max-width: 1024px) {
        margin: 15px 30px;
    }

    @media screen and (max-width: 768px) {
        margin: 10px 20px;
    }
}

.qrCodeContainer {
    width: 250px;
    aspect-ratio: 1;
    margin-bottom: 20px;

    @media screen and (max-width: 1400px) {
        width: 200px;
        margin-bottom: 15px;
    }

    @media screen and (max-width: 1024px) {
        width: 180px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
        width: 200px;
        margin-bottom: 5px;
    }

    @media screen and (max-width: 480px) {
        width: 180px;
    }
}

.qrCode {
    border-radius: 10px;
}

.storeImageContainer {
    width: 250px;
    aspect-ratio: 3.250;

    @media screen and (max-width: 1400px) {
        width: 200px;
    }

    @media screen and (max-width: 1024px) {
        width: 180px;
    }

    @media screen and (max-width: 768px) {
        width: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 180px;
    }
}

.appleImage,
.googleImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}