@import './../../consts/LAYOUT.scss';

.container {
    align-self: center;
    width: 100vw;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.youtube {
    width: 90vw;
    aspect-ratio: 1.777;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.youtubeIFrame {
    width: 100%;
    height: 100%;
}