@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    min-height: 105vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $brandYellow;
    padding: 20px; // Adds spacing to prevent content from touching edges

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 480px) {
        padding: 10px;
    }
}

.title {
    font-weight: 600;
    color: $black;
    font-size: 24px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px; // Reduce font size on tablets
    }

    @media (max-width: 480px) {
        font-size: 18px; // Reduce font size further on smaller screens
    }
}