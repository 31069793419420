@import './../../consts/LAYOUT.scss';

.container {
    height: 100px;
    width: 100vw;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
}

.button {
    background-color: $accent;
    color: $black;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.spacer {
    width: 150px;
}

.logoutButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $primary;
    font-size: 20px;
    height: 36px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
}

.rightIconContainer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.rightContainer {
    position: absolute;
    right: 0px;
    top: 40px;
    border-radius: 10px;
    padding: 20px;
    background-color: $white;
}

.appStoreIcon {
    color: $accent;
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;

}

.playStoreIcon {
    color: $accent;
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}


.iosQrCodeContainer {
    width: 220px;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 80px;
    top: 40px;
    text-align: center;
}

.androidQrCodeContainer {
    width: 220px;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 40px;
    top: 40px;
    text-align: center;
}

.qrCode {
    width: 200px;
    height: 200px;
}

.qrCodeText {
    font-size: 14px;
    font-weight: 600;
}

.glowOnHover {
    height: 34px;
    width: 146px;
    border: none;
    outline: none;
    color: $black;
    background: $accent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
}

.glowOnHover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -3px;
    left: -3px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 3px;
}

.glowOnHover:hover:before {
    opacity: 1;
}

.glowOnHover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $accent;
    left: 0;
    top: 0;
    border-radius: 3px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

/* Responsive Scaling */
@media (max-width: 1024px) {
    .glowOnHover {
        height: 30px;
        width: 130px;
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .glowOnHover {
        height: 28px;
        width: 120px;
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .glowOnHover {
        height: 24px;
        width: 110px;
        font-size: 12px;
    }
}