@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: 'center';
}

.title {
    align-self: center;
    margin-top: 60px;
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
    color: $white;
    white-space: pre-wrap;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.faqContainer {
    align-self: center;
    width: 65vw;
    margin-bottom: 60px;
    white-space: pre-wrap;
}

.itemContainer {
    // margin-bottom: 5px;
    cursor: pointer;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 0px 15px;
    border-radius: 6px;
    background-color: $darkishGrey;
    transition: background-color 0.1s ease-in-out;
    position: relative;
}

.rowContainer.visible,
.rowContainer:hover {
    background-color: $lighterGrey;
}

.question {
    font-size: 24px;
    color: $white;
    display: flex;
    flex: 1
}

.plusIcon {
    color: $white;
    font-size: 30px;
    transform: rotateZ(0deg);
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.plusIcon.rotate {
    color: $accent;
    transform: rotateZ(135deg);
}

.content {
    font-size: 20px;
    color: $white;
    max-height: 0;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 8px;
    transition: background-color 0.2s ease-in-out, max-height 0.2s ease-in-out, margin-top 0.2s ease-in-out, padding-bottom 0.2s ease-in-out, padding-top 0.2s ease-in-out;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: $black;
}

.content.show {
    max-height: 1000px;
    margin-top: 2px;
    margin-bottom: 8px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
    background-color: $darkishGrey;
    transition: background-color 0.2s ease-in-out, max-height 0.2s ease-in-out, margin-top 0.2s ease-in-out, padding-bottom 0.2s ease-in-out, padding-top 0.2s ease-in-out;
    border-radius: 6px;
}

.inputContainer {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.inputTitle {
    font-size: 22px;
    font-weight: 500;
    color: $white;
    margin-bottom: 26px;
}

.inputRow {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.input {
    height: 55px;
    width: 33vw;
    background-color: $black;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid $grey;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color: $white;
    caret-color: $accent;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    color: $black;
    background-color: $accent;
    font-weight: 700;
    font-size: 18px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.buttonChevron {
    margin-left: 10px;
    margin-right: -5px;
}

.singleButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    height: 56px;
    color: $black;
    background-color: $accent;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    cursor: pointer;
}


.glowOnHover {
    height: 56px;
    width: 160px;
    border: none;
    outline: none;
    color: $black;
    background: $accent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    align-self: center;
}

.glowOnHover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -3px;
    left: -3px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 3px;
}

.glowOnHover:hover:before {
    opacity: 1;
}

.glowOnHover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $accent;
    left: 0;
    top: 0;
    border-radius: 3px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.bold {
    font-weight: 700;
}

@media (max-width: 1400px) {
    .faqContainer {
        width: 70vw;
    }

    .title {
        font-size: 45px;
    }

    .rowContainer {
        min-height: 60px;
    }

    .question {
        font-size: 22px;
    }

    .plusIcon {
        font-size: 28px;
    }

    .content {
        font-size: 18px;
    }
}

@media (max-width: 1024px) {
    .faqContainer {
        width: 80vw;
    }

    .title {
        font-size: 40px;
    }

    .rowContainer {
        min-height: 56px;
    }

    .question {
        font-size: 20px;
    }

    .plusIcon {
        font-size: 26px;
    }

    .content {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .faqContainer {
        width: 90vw;
    }

    .title {
        font-size: 35px;
    }

    .rowContainer {
        min-height: 52px;
    }

    .question {
        font-size: 18px;
    }

    .plusIcon {
        font-size: 24px;
    }

    .content {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .faqContainer {
        width: 90vw;
    }

    .title {
        font-size: 30px;
    }

    .rowContainer {
        min-height: 48px;
    }

    .question {
        font-size: 16px;
    }

    .plusIcon {
        font-size: 22px;
    }

    .content {
        font-size: 14px;
    }
}