@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: space-between;
}

.rowContainer {
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.itemContainer {
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.linkItem {
    font-size: 13px;
    font-weight: 500;
    color: $textGrey;
    text-decoration: underline;
}

.imageContainer {
    position: relative;
    height: 63.2px;
    aspect-ratio: 3.25;
    margin-left: 30px;
}

.platformsContainer {
    display: flex;
    flex-direction: row;
}

.socialMediaContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.iconContainer {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid $white;
    cursor: pointer;
}

.icon {
    margin: -4px;
    color: $white;
    font-size: 60px;
}

// Responsive styles
@media (max-width: 1400px) {
    .imageContainer {
        height: 55px;
    }
}

@media (max-width: 1024px) {
    .imageContainer {
        height: 45px;
    }
}

@media (max-width: 768px) {
    // .rowContainer {
    //     flex-direction: column;
    // }

    .imageContainer {
        height: 40px;
        margin-left: 0;
    }

    .platformsContainer {
        order: -1;
    }
}

@media (max-width: 480px) {
    .container {
        height: auto;
    }

    .rowContainer {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
    }

    .imageContainer {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 35px;
    }
}