@import './../consts/LAYOUT.scss';

html {
    scroll-behavior: smooth;
}

.container {
    color: white;
    padding: 30px 8%;
    font-size: 16px;
    text-align: justify;
    font-weight: 300;
}

.largeTitle {
    font-weight: 800;
    font-size: 34px;
    margin-bottom: 20px;
    text-align: center;
}

.title {
    font-weight: 800;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    color: $accent;
}

.subtitle {
    font-weight: 800;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 5px;
    color: $accent;
}

.linksContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.link {
    color: $accent;
    text-decoration: underline;
}

.listItemContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.email {
    text-decoration: underline;
}

.bold {
    font-weight: 800;
}

li {
    margin-left: 30px;
    list-style-type: disc;
}

li::marker {
    color: $accent;
}

@media screen and (max-width: 800px) {
    .container {
        padding: 30px 1%;
        font-size: 14px;
    }

    .largeTitle {
        font-size: 24px;
    }

    .title {
        font-size: 18px;
    }

    .listItemContainer {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    li {
        margin-left: 15px;
    }
}