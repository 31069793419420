@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden; // Prevent horizontal scrolling
    // border: 1px solid red;
}

.contentContainer {
    min-height: 90vh;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // border: 1px solid blue;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap; // Ensures items wrap on smaller screens
    // border: 1px solid green;
}

.title {
    font-size: 45px; // Slightly smaller
    line-height: 48px;
    font-weight: 700;
    color: $white;
    white-space: pre-wrap;
    text-align: center;
}

.subtitle {
    font-size: 22px; // Slightly smaller
    font-weight: 500;
    color: $white;
    white-space: pre-wrap;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.imageContainer {
    align-self: center;
    height: 350px;
    aspect-ratio: 13080 / 822;
    overflow: visible;
    // border: 3px solid purple;
    transform: translateX(0px);
    animation: slideInRight 30s infinite alternate linear;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: visible;
    // border: 3px solid yellow;
}

@keyframes slideInRight {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(-2000px);
    }
}

.quote {
    font-size: 22px; // Slightly smaller
    font-style: italic;
    color: $white;
    white-space: pre-wrap;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.singleButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    height: 50px; // Slightly smaller
    color: $black;
    background-color: $accent;
    font-weight: 700;
    font-size: 16px; // Slightly smaller
    border-radius: 3px;
    align-self: center;
}

.glowOnHover {
    height: 50px; // Slightly smaller
    width: 220px; // Slightly smaller
    border: none;
    outline: none;
    color: $black;
    background: $accent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px; // Slightly smaller
    align-self: center;
}

.glowOnHover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -3px;
    left: -3px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 3px;
}

.glowOnHover:hover:before {
    opacity: 1;
}

.glowOnHover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $accent;
    left: 0;
    top: 0;
    border-radius: 3px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .contentContainer {
        padding: 0 5%;
    }

    .title {
        font-size: 38px; // Slightly smaller
        line-height: 40px;
    }

    .subtitle {
        font-size: 18px; // Slightly smaller
    }

    .quote {
        font-size: 18px; // Slightly smaller
    }
}

@media (max-width: 768px) {
    .contentContainer {
        padding: 0 3%;
    }

    .title {
        font-size: 30px; // Slightly smaller
        line-height: 34px;
    }

    .subtitle {
        font-size: 16px; // Slightly smaller
    }

    .quote {
        font-size: 16px; // Slightly smaller
    }

    .glowOnHover {
        height: 45px; // Slightly smaller
        width: 190px; // Slightly smaller
        font-size: 14px; // Slightly smaller
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 26px; // Slightly smaller
        line-height: 30px;
    }

    .subtitle,
    .quote {
        font-size: 14px; // Slightly smaller
    }

    .glowOnHover {
        height: 40px; // Slightly smaller
        width: 160px; // Slightly smaller
        font-size: 12px; // Slightly smaller
    }
}