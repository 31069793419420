@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    min-height: 70vh;
    display: flex;
    justify-content: center;
}

.rowContainer {
    min-height: 70vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap; // Allows wrapping on smaller screens
    gap: 20px; // Adds spacing when stacked
    text-align: center; // Ensures text is centered when stacked
}

.title {
    font-size: 50px;
    font-weight: 700;
    color: $white;
}

.subtitle {
    font-size: 24px;
    font-weight: 600;
    color: $white;
}

.imageContainer {
    position: relative;
    max-height: 55vh;
    aspect-ratio: 1.140;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .rowContainer {
        // height: auto;
    }

    .title {
        font-size: 40px;
    }

    .subtitle {
        font-size: 20px;
    }

    .imageContainer {
        max-height: 50vh;
    }
}

@media (max-width: 768px) {
    .rowContainer {
        // min-height: auto;
    }

    .title {
        font-size: 32px;
    }

    .subtitle {
        font-size: 18px;
    }

    .imageContainer {
        max-height: 40vh;
    }
}

@media (max-width: 480px) {
    .rowContainer {
        flex-direction: column; // Stacks elements when screen is too small
        // height: auto;
    }

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 16px;
    }

    .imageContainer {
        max-height: 35vh;
    }
}