@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.contentContainer {
    width: 100vw;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px;
}

.title {
    font-size: 3rem; // Scales well for different devices
    font-weight: 700;
    color: $white;
    text-align: center;
}

.price {
    font-size: 1.5rem;
    font-weight: 500;
    color: $white;
    text-align: center;
}

.inputContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.inputTitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: $white;
    text-align: center;
}

.inputRow {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.input {
    height: 50px;
    width: 80vw;
    max-width: 400px;
    background-color: $black;
    padding: 10px;
    border: 1px solid $grey;
    border-radius: 3px;
    color: $white;
    caret-color: $accent;
}

::selection {
    background-color: $accent;
    color: $black;
}

:focus {
    outline: none;
    border: 1px solid $accent;
}

.button,
.singleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    color: $black;
    background-color: $accent;
    font-weight: 700;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.buttonChevron {
    margin-left: 10px;
    margin-right: -5px;
}

.glowOnHover {
    height: 50px;
    width: 140px;
    border: none;
    outline: none;
    color: $black;
    background: $accent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
}

.glowOnHover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -3px;
    left: -3px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 5px;
}

.glowOnHover:hover:before {
    opacity: 1;
}

.glowOnHover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $accent;
    left: 0;
    top: 0;
    border-radius: 5px;
}

/* Responsive Design */

@media (max-width: 1024px) {
    .title {
        font-size: 2.5rem;
    }

    .price {
        font-size: 1.25rem;
    }

    .input {
        width: 70vw;
        max-width: 350px;
    }

    .button,
    .singleButton {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    .glowOnHover {
        height: 45px;
        width: 130px;
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }

    .price {
        font-size: 1.2rem;
    }

    .input {
        width: 90vw;
    }

    .button,
    .singleButton {
        font-size: 0.85rem;
        padding: 7px 12px;
    }

    .glowOnHover {
        height: 40px;
        width: 120px;
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 1.8rem;
    }

    .price {
        font-size: 1rem;
    }

    .input {
        width: 95vw;
    }

    .button,
    .singleButton {
        font-size: 0.8rem;
        padding: 6px 10px;
    }

    .glowOnHover {
        height: 38px;
        width: 110px;
        font-size: 0.8rem;
    }
}