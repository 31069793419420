// backgrund container that should cover the entire page
.container {
    position: absolute;
    top: -85vh;
    left: -200px;
    right: -200px;
    height: 200%;
    // z-index: 0;
    background-color: #000000;

}

.rowContainer {
    // border: 1px solid red;
}

.image {
    height: 300px;
    aspect-ratio: 18.892;
    // border: 1px solid blue;
}

.imageContainer1 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.5;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(0px);
    animation: slideInRight 35s infinite alternate; // Apply the animation to the row class
    // border: 1px solid yellow;
}

.imageContainer2 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.2;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(-500px);
    animation: slideInLeft 90s infinite alternate; // Apply the animation to the row class
}

.imageContainer3 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.1;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(0px);
    animation: slideInRight 35s infinite alternate; // Apply the animation to the row class
}

.imageContainer4 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.5;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(-500px);
    animation: slideInLeft 40s infinite alternate; // Apply the animation to the row class
}

.imageContainer5 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.1;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(0px);
    animation: slideInRight 70s infinite alternate; // Apply the animation to the row class
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

@keyframes slideInRight {
    0% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(0px); // Start with rotation and translation
    }

    100% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(-1000px); // End with rotation and translation
    }
}

@keyframes slideInLeft {
    0% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(-1000px); // Start with rotation and translation
    }

    100% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(0px); // End with rotation and translation
    }
}