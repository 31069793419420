@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    min-height: 70vh;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    padding: 40px 0; // Adds space between ThickDivider and content
}

.rowContainer {
    min-height: 65vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: left; // Ensures text stays aligned correctly
    padding: 40px;
    // padding-top: 40px;
    // padding-bottom: 40px;

}

/* Text Styling */
.title {
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    color: $white;
    white-space: pre-wrap;
    margin-bottom: 15px;
}

.subtitle {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: $white;
    white-space: pre-wrap;
}

/* Image Styling */
.imageContainer {
    position: relative;
    max-height: 43vh;
    aspect-ratio: 1.972;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
    .imageContainer {
        max-height: 38vh;
    }
}

@media (max-width: 1200px) {
    .imageContainer {
        max-height: 36vh;
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .rowContainer {
        flex-direction: column-reverse; // Text appears first, followed by the image
        height: auto;
        align-items: center;
        text-align: center;
        gap: 30px; // Adds spacing between text and image when stacked
    }

    .title {
        font-size: 38px;
        line-height: 42px;
    }

    .subtitle {
        font-size: 20px;
        line-height: 24px;
    }

    .imageContainer {
        max-height: 30vh;
    }
}

/* Switch to stacked layout on smaller screens */
@media (max-width: 768px) {
    .title {
        font-size: 32px;
        line-height: 36px;
    }

    .subtitle {
        font-size: 18px;
        line-height: 22px;
    }

    .imageContainer {
        max-height: 25vh;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 28px;
        line-height: 32px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .imageContainer {
        max-height: 18vh;
    }
}